<template>
    <section>
        <iHeader title="报价详情" @back-event="closeWebview"/>
        <div class="publicHead">
          <div class="tips" v-if="tips.tips">
            <p class="tipsTitle">注意事项</p>
            <div>
              {{tips.tips}}
              <p class="fileInfo" @click="fieldCall(tips.file)" v-if="tips.file">{{tips.file.title}}</p>
            </div>
          </div>
        </div>
        <i-scrollBox class="opOrderListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
            <template v-slot:scrollBox>
                <div class="venueItemBox" v-if="venueList.length">
                    <div class="itemTitle">酒店会场<span></span></div>
                    <div class="scrollBox">
                        <div class="scrollWidth">
                            <div class="venueItem" v-for="(item, index) in venueList" :key="item.key">
                                <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="venueItemBox" v-if="mealList.length">
                    <div class="itemTitle">酒店用餐<span></span></div>
                    <div class="scrollBox">
                        <div class="scrollWidth">
                            <div class="venueItem" v-for="(item, index) in mealList" :key="item.key">
                                <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="venueItemBox" v-if="accommodationList.length">
                    <div class="itemTitle">酒店住宿<span></span></div>
                    <div class="scrollBox">
                        <div class="scrollWidth">
                            <div class="venueItem" v-for="(item, index) in accommodationList" :key="item.key">
                                <venue-item @goDetail="goDetail" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="venueItemBox" v-if="otherList.length">
                    <div class="itemTitle">酒店其他<span></span></div>
                    <div class="scrollBox">
                        <div class="scrollWidth">
                            <div class="venueItem" v-for="(item, index) in otherList" :key="item.key">
                                <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </i-scrollBox>
      <div class="confirmOfferBox">
        <div class="confirmOfferInfo">
          价格有效期及资源保留至
        </div>
        <div class="confirmOfferDate">
          {{(proposalSupplier.quoteDeadTime || '') | formatDate}}
        </div>
        <div class="confirmOfferInfo">
          取消条款
        </div>
        <div class="confirmOfferDate">
          <p>{{cancelClause.noFeeDate}}前取消，不收取违约金</p>
          <p>{{cancelClause.contractAmount}}前取消，按报价的{{cancelClause.expenseRatio}}%收取违约金</p>
          <p>{{cancelClause.allCost}}前取消，按报价的全额收取违约金</p>
        </div>
        <div class="confirmOfferInfo">
          其他条款
        </div>
        <div class="confirmOfferDate">
          {{proposalSupplier.clause}}
        </div>
      </div>
      <div class="footerBtnListBox" v-show="isConfirmOffer">
        <p v-for="(item, index) in btnList" :key="index" :style="{background:item.color}" @click="actionClick(item)">{{item.actName}}</p>
      </div>
    </section>
</template>
<script>
    import {tenant} from './tenant.js'
    import venueItem from "./components/venueItem";
    import iScrollBox from "@/components/iScrollBox/iScrollBox";
    import Prompt from '@/components/prompt/plugin';
    import { Field} from 'vant';
    export default {
        name: 'requirementsList',
        mixins: [tenant],
        components: {
            venueItem,
            iScrollBox,
            [Field.name]: Field,
        },
        data () {
            return {
                demandList: [],
                venueList: [],
                mealList: [],
                accommodationList: [],
                otherList: [],
                show: false,
                reason: '',
                tips: {},
                btnList: [
                    {
                        actName: '不接受',
                        color: '#fb304d',
                        code: 'reject',
                        funName: 'acceptQuotation'
                    },
                    {
                        actName: '退回重报',
                        color: '#ffb52a',
                        code: '',
                        funName: 'rereport'
                    },
                    {
                        actName: '接受报价',
                        color: '#2d82f0',
                        code: 'accept',
                        funName: 'acceptQuotation'
                    }
                ],
                title: '',
                loadType: '',
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                visible: false,

            }
        },
        computed: {
          isConfirmOffer () {
              return [11].includes(this.proposalSupplier.status)
          }
        },
        methods: {
            scrollTop() {
                this.loadType = 'top';
                this.pageIndex = 1;
                this.init(true)
            },
            scrollBottom() {
                if (this.pageSize * this.pageIndex >= this.total) {
                    this.loadType = '';
                } else {
                    this.loadType = 'bottom';
                    this.pageIndex += 1;
                    this.init();
                }
            },
            goDetail (item) {
                this.$SmartStorage.set('quotePriceItem', item)
                this.$router.push('/quotedPriceDetailsOrder')
            },
            confirmOffer () {
                this.visible = true
            },
            actionClick (item) {
                this[item.funName](item)
            },
            acceptQuotation (item) {
                this.$dialog.confirm({
                    title: '提示',
                    message: item.code == 'accept' ? `您已确认由${this.proposalSupplier.name}为您提供会议服务，其它酒店的相关报价会自动退回` : '是否确认不接受本酒店的报价',
                }).then(async () => {
                    let params = {
                        proposalId: this.proposalId,
                        supplierId: this.supplierId,
                        operationType: item.code,
                        orderId: this.$SmartStorage.get('orderId'),
                    }
                    let response = await this.$service.supplierSelected(params);
                    if (response.success) {
                        if(item.code == 'accept') {
                            this.$toast('接受报价成功')
                        }else{
                            this.$toast('不接受报价成功')
                        }
                        this.$router.back()
                    }
                })
            },
            rereport () {
                Prompt.show({
                    title: "请输入退回原因",
                    onConfirm: async (val) => {
                        let params = {
                            proposalId: this.proposalId,
                            supplierId: this.supplierId,
                            reason: val,
                            formData: JSON.stringify({supplierId: this.supplierId}),
                            taskData: JSON.stringify({rebackReason: val}),
                            operationType: 2, // 提交 1，退回报价 2，退回结算3，退回损失 4
                            orderId: this.$SmartStorage.get('orderId'),
                        }
                        let response = await this.$service.createTask(params);
                        if (response.success) {
                            this.$toast('退回报价成功')
                            this.$router.back()
                        }
                    }
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "quotedPriceDemandList";
</style>
