<template>
  <van-dialog v-model="show" :title="title" show-cancel-button @confirm="confirm" @cancel="cancel">
    <p v-html="messageTxt" style="padding: 10px 16px;"></p>
    <van-field
      v-model="message"
      rows="2"
      autosize
      label=""
      type="textarea"
      maxlength="50"
      placeholder="请输入"
      show-word-limit
    />
  </van-dialog>
</template>

<script>
  import { Field , Dialog} from 'vant';
export default {
  components: {
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
      show: {
          type: Boolean,
          default: false
      }
  },
  data() {
    return {
      title: "",
      messageTxt: "",
      message: "",
    };
  },
  created() {},
  methods: {
      confirm() {
          this.$emit("confirm", this.message);
          this.show = false;
      },
      cancel() {
          this.show = false;
      }
  }
};
</script>

<style scoped lang="scss"></style>
