'use strict'
import Vue from "vue";
import Prompt from './Prompt.vue'

const PromptHandle = Vue.extend(Prompt);
let instance;

export default {
    show({ title, onConfirm , message = ''}) {
        instance = new PromptHandle({
            el: document.createElement('div'),
            data: {
                title: title,
                messageTxt: message,
            }
        })
        instance.show = true;

        instance.$on("confirm", function(res) {
            onConfirm(res);
        });

        document.body.appendChild(instance.$el)
        // Vue.nextTick(() => {

        // });
    },
    close() {
        instance.$modal.hide("simulator");
        instance.visible = false;
    }
}
